/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* @intention: Determines if Link routes internally or externally.
 * If externally, this adds target="_blank" to rendered <a> element.
 * !!! NOTE: This requires <Link> to use 'to={{ pathname: "QUALIFIED ABSOLUTE PATH" }}' format.
 * If this requirement is ignored, full url will try to open within domain and therefore fail.
 *
   Usage example:

   to={{ pathname: panel.linking?.link?.href }}
   // eslint-disable-next-line react/jsx-props-no-spreading
   {... openInNewTab(panel.linking?.link?.href)}
   -renders <a target="_blank" href="https://wolfermans.cashstar.com/gift-card/buy">
 *
 * @param {string} - value of dataObj.link.href
 * @return {obj} e.g. {target: '_blank'} if true, "{}" if false
 */

export default function openInNewTab(path) {
    if (!path) return {};
    if (path.indexOf('http') !== -1) {
        const obj = {};
        obj.target = '_blank';
        return obj;
    }
    return {};
}
