/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getLinkReplacement = (abMenuReplacement, title = '', href = '', isTitle = true) => {
    const formattedTitle = (title || '')?.trim?.()?.toLowerCase();
    if (abMenuReplacement?.type === 'variant') {
        const replacementItem = abMenuReplacement?.items?.[formattedTitle];
        if (replacementItem) {
            return isTitle ? replacementItem?.label : replacementItem?.link || href;
        }
    }
    return isTitle ? title : href;
};
export default getLinkReplacement;
