/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import ReactHTMLParser from 'react-html-parser';

const cleanText = (str) => {
    const re = /<p>|<\/p>|<strong>|<\/strong>|\\n/g; // strips <p>,</p>,<strong>,</strong> and \n
    return ReactHTMLParser(str.replace(re, ''));
};

export default cleanText;
