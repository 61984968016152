/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React from 'react';
import {
    string, func, shape, arrayOf, number, object,
} from 'prop-types';
import mbpLogger from 'mbp-logger';

// helpers
import { GRAPHQL_ENV } from '../../../../gql';

// partials/components
import NavigationMenu from './NavigationMenu_V2';

const NavigationV2 = ({
    entryData, brand, ariaLabel, trackEvent, keeperData,
}) => {
    if (!entryData?.menu_ref?.[0]) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            component: 'Navigation_V2.js',
            message: `No usable data passed to component\n brand data = ${brand.domain}`,
            env: GRAPHQL_ENV,
        });
        return <></>;
    }

    if (entryData !== undefined) return <NavigationMenu keeperData={keeperData} data={entryData} ariaLabel={ariaLabel} trackEvent={trackEvent} />;
    return <></>;
};

NavigationV2.propTypes = {
    entryData: shape({
        menu_group: arrayOf(shape({
            group_title: string,
            display_text: string,
            link_group: arrayOf(shape({
                uid: string,
                _content_type_uid: string,
            })),
            promoted_spots: arrayOf(shape({
                uid: string,
                _content_type_uid: string,
            })),
            linking: shape({
                tracking_event_category: string,
                tracking_event_action: string,
                tracking_event_label: string,
            }),
        })),
        styling: shape({
            fonts: shape({
                department: string,
                category: string,
                subcategory: string,
                subsubcategory: string,
                promoted: string,
                font_sizes: shape({
                    main_level: number,
                    dd_main_level: number,
                    dd_second_level: number,
                    drop_down_third_level: number,
                    dd_promo: number,
                }),
            }),
            colors: shape({
                bg_nav: shape({ color: string }),
                main_link: shape({ color: string }),
                main_link_hover: shape({ color: string }),
                bg_main_link_hover: shape({ color: string }),
                bg_drop_down: shape({ color: string }),
                drop_down_link: shape({ color: string }),
                drop_down_link_hover: shape({ color: string }),
                bg_drop_down_link: shape({ color: string }),
            }),
        }),
    }).isRequired,
    brand: shape({
        domain: string,
    }),
    ariaLabel: string.isRequired,
    trackEvent: func,
    keeperData: object,
};
NavigationV2.defaultProps = {
    brand: {
        domain: 'harryanddavid',
    },
    trackEvent: () => {},
    keeperData: {},
};
export default NavigationV2;
