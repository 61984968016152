/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { func } from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        top: '100%',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#00000040',
        opacity: '1',
    },
}));

const Backdrop = ({ closeDropdown }) => {
    const classes = useStyles();
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    return <div onMouseOver={closeDropdown} onTouchStart={closeDropdown} className={classes.root} />;
};

Backdrop.propTypes = {
    closeDropdown: func.isRequired,
};
export default Backdrop;
